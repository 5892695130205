$(function () {
    $('#form-contact')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();
            $('.contact-form-error').hide();
            $.post(form.attr("action"), form.serialize(), function (response) {
            	if(response.status == "OK"){
					$('.contact__main__form > .form-col-main').hide();
					$('.contact__main__form__success').show();
					dataLayer.push({'event': 'ContactFormSubmit'});
            	}else{
            		$('.contact-form-error').show();
            	}
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        });
    
    $('#form__offerte')
    .on('formvalid.zf.abide', function (e, form) {
        e.preventDefault();
        $('.contact-form-error').hide();
        $.post(form.attr("action"), form.serialize(), function (response) {
            if(response.status == "OK"){
                $('.training__offerte-off-canvas__formulier__form').hide();
                $('.contact__main__form__success').show();
                dataLayer.push({'event': 'OfferteFormSubmit'});
            }else{
                $('.contact-form-error').show();
            }
        });
    })
    .on("submit", function (e) {
        e.preventDefault();
    });

    $('#form__download')
    .on('formvalid.zf.abide', function (e, form) {
        e.preventDefault();
        $('.contact-form-error').hide();
        $.post(form.attr("action"), form.serialize(), function (response) {
            if(response.status == "OK"){
                $('.training__download-off-canvas__formulier__form').hide();
                $('.contact__main__form__success').show();
                dataLayer.push({'event': 'OfferteFormSubmit'});
            }else{
                $('.contact-form-error').show();
            }
        });
    })
    .on("submit", function (e) {
        e.preventDefault();
    });
    

    $('#form-dtyc')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();
			var formData = new FormData(this);
            $.post(form.attr("action"), formData, function (response) {
                $('.contact__main__form > .form-col-main').hide();
                $('.contact__main__form__success').show();
            	dataLayer.push({'event': 'DTyCFormSubmit'});
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        });
            
    $('#form-contact-inline')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();

            $.post(form.attr("action"), form.serialize(), function (response) {
                $('#opleidingsbudget-done').foundation('open');
            	dataLayer.push({'event': 'OpleidingsbudgetFormSubmit'});
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        });
        
    $('#form-inviteEmail')
    .on('formvalid.zf.abide', function (e, form) {
        e.preventDefault();
        $('.contact-form-error').hide();
        $.post(form.attr("action"), form.serialize(), function (response) {
            if(response.status == "OK"){
                $('#form-inviteEmail').hide();
                $('.contact__main__form__success').show();
                dataLayer.push({'event': 'ContactFormSubmit'});
            }else{
                $('.contact-form-error').show();
            }
        });
    })
    .on("submit", function (e) {
        e.preventDefault();
    });
    
    $('.showInviteForm').on('click', function(){
        $('#form-inviteEmail').get(0).reset();
        $('#form-inviteEmail').show();
        $('.contact__main__form__success').hide();
    });
    
});