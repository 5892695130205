$(function () {
	var $headerselector = '.training__section-header--sub';
	var $sectionselector = '.training__section--sub__content';
    var $header = $($headerselector);
    var $section = $($sectionselector);

    if($header.length > 0 ) {
        $header.addClass('closed');
        $section.addClass('closed');

        $header.on('click', function (e) {
            if( $(this).hasClass('closed') ) {
                $(this).removeClass('closed');
                $(this).next().removeClass('closed');
            }
            else {
                $(this).addClass('closed');
                $(this).next().addClass('closed');
            }
        });
    }

    // Select all elements with the class 'training__sidebar__eerstvolgende'
    const sidebarLinks = document.querySelectorAll('.training__sidebar__eerstvolgende');

    // Attach a click event listener to each link
    sidebarLinks.forEach(link => {
        link.addEventListener('click', function(e) {
            e.preventDefault(); // Prevent the default link behavior

            // Find the element with the ID 'cursusdata'
            const section = document.getElementById('cursusdata');

            // Scroll to the 'cursusdata' section
            section.scrollIntoView({ behavior: 'smooth' });
        });
    });

    // Select the checkbox by its ID
    const checkbox = document.getElementById('showremarks');

    // Select the textarea by its name
    const textarea = document.querySelector('textarea[name="wensen"]');
    if(checkbox){
        // Add an event listener to the checkbox
        checkbox.addEventListener('change', function() {
            // Toggle the display property based on checkbox checked status
            if (checkbox.checked) {
                textarea.style.display = 'block';  // Show textarea
            } else {
                textarea.style.display = 'none';   // Hide textarea
            }
        });
    }

    $("#download_Form").show();
    $("#account_Form").hide();
    $("#DowloadToNextStep, #download_form_step2").click(function(){
        $("#account_Form").show()
        $("#download_Form").hide();
        $("#download_form_step1").removeClass("selected");
        $("#download_form_step2").addClass("selected");
        $("#downoad_nummer-veranderen").html('2');
        $("#dowloads-punten2").html('Hoe je de nieuwe skills toepast in de praktijk');
        $("#dowloads-punten3").html('Alles wat jij moet weten over deze training');
    });

    $("#download_form_step1").click(function(){
        $("#account_Form").hide()
        $("#download_Form").show();
        $("#download_form_step1").addClass("selected");
        $("#download_form_step2").removeClass("selected");
        $("#downoad_nummer-veranderen").html('1');
        $("#dowloads-punten2").html('Hoe je je kunt voorbereiden op de training');
        $("#dowloads-punten3").html('Wat de vervolgstappen na deze training zijn');
    });
});