var submitted = false;
$(document)
  // to prevent form from submitting upon successful validation
  .on("submit", function(ev) {
    if(ev.target.id == "form-evaluatie"){
      if(submitted == true){
        ev.preventDefault();
        console.log("Submit for form id "+ev.target.id+" intercepted");
      }else{
        submitted = true;
        console.log("Submitted");
      }
    }
  });

$('form #training').on('change', function(){
    if($(this).val() != "0"){
        $('.hidebydefault').show();
    }else{
        $('.hidebydefault').hide();

    }
}); 