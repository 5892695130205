function sendloginlink(selector = false){
	//$('.email-exists').hide();
	//$('.email-exists-errors').hide();
	//$('.email-loginlink-sent').show();
	//var emailaddress = $('input.check-email-exists').val();
	if(selector == false){
		var emailaddress = $('#login-form input[type="email"]').val();
		$('#login-form #form-errors').empty().hide();
		$('#login-form #form-success').empty().hide();
	}else{
		var emailaddress = $(selector+' #login-form input[type="email"]').val();
		$(selector+' #login-form #form-errors').empty().hide();
		$(selector+' #login-form #form-success').empty().hide();
	}
	var json = {'email': emailaddress};
	console.log(json);
	$.ajax({
		type: "POST",
		url : "/loginlinkversturen",
		data : json,
		dataType: "json",
		success : function(data){
		
			console.log(data);
			
			if(selector == false){
				$('.login-off-canvas__section.login').hide();
				$('.login-off-canvas__section.createaccount').hide();
				$('.login-off-canvas__section.setpassword').hide();
				$('.login-off-canvas__section.loginmessage').show();
				$('.login-off-canvas__section.checkifexists').hide();

				$("#loginSidebar").find( ".close-button" ).click(function(){
					$('.login-off-canvas__section.checkifexists').show();
					$('.login-off-canvas__section.createaccount').hide();
					$('.login-off-canvas__section.setpassword').hide();
					$('.login-off-canvas__section.loginmessage').hide();
					$('.login-off-canvas__section.login').hide();
				}); 

			}else{
				Html = '<ul class="list-unstyled">';
				Html += '<li style="color:#35393b;">Er is een login link naar je onderweg</li>';
				Html += '</ul>';
				$(selector+' #login-form #form-success').html( Html ).show();
			}
			//$('.email-exists-errors').hide();
			//$('.email-loginlink-sent').hide();
			/*setTimeout(
				function()
				{
					
					$('.email-loginlink-sent').hide();
					

				}, 1500);*/
		},
		error : function(data){
			var errors = $.parseJSON(data.responseText);
			//console.log(errors);
			console.log('error');
			setTimeout(
				function()
				{

					errorsHtml = '<ul class="list-unstyled">';
					$.each( errors , function( key, value ) {
						if(key == "message"){
						//console.log(value);
						if(value[0] == "You shall not pass!"){
							value[0] = 'We konden niet verifiëren of je een echte gebruiker bent';
						}
						errorsHtml += '<li style="color:#35393b;">' + value[0] + '</li>'; //showing only the first error.
						}
					});
					errorsHtml += '</ul>';
					if(selector == false){
						$('#login-form #form-errors').html( errorsHtml ).show();
					}else{
						$(selector+' #login-form #form-errors').html( errorsHtml ).show();
					}
				

				}, 1500);

		}

	});
}
function hasDimensions(element) {
    return element.offsetWidth > 0 && element.offsetHeight > 0;
}
$(function () { 
	$('.showpassword').on('click', function(){
		var element = $(this).parent().find('.passwordfield');
		if(element.attr('type') == "password"){
			$(this).text('Verberg');
			element.attr('type','text');
		}else{
			$(this).text('Toon');
			element.attr('type','password');

		}

	});
	$('.changeuser').on('click', function(){
		$('.login-off-canvas__section.login').hide();
		$('.login-off-canvas__section.createaccount').hide();
		$('.login-off-canvas__section.loginmessage').hide();
		$('.login-off-canvas__section.setpassword').hide();
		$('.login-off-canvas__section.checkifexists').show();

	});
	$('#checkifexists-form').on('submit', function(e){
		e.preventDefault();
		var $btn = $(this).find('button[type="submit"]');
		var formid = $(this).attr('id');
		$(this).find('#form-errors').empty().hide();
		$(this).find('#form-success').empty().hide();

		var form = jQuery(this);
		var dataString = form.serialize();
		var formAction = form.attr('action');

		$.ajax({
			type: "POST",
			formid : formid,
			url : formAction,
			data : dataString,
			success : function(data){
			
				console.log(data);

				setTimeout(
					function()
					{
						successHtml = '<ul class="list-unstyled">';
						$.each( data , function( key, value ) {
							//console.log(value);
							successHtml += '<li style="color:#35393b;">' + value[0] + '</li>'; //showing only the first error.
						});
						successHtml += '</ul>';
						$('#'+formid+' #form-success').html( successHtml ).show();

					}, 1500);
				var email = $('#checkifexists-form input[type="email"]').val();
				$('.login-off-canvas__form input[type="email"]').val(email);
				$('.selecteduser').text(email);
				if(data.status == "OK"){
					
					$('.login-off-canvas__section.checkifexists').hide();
					$('.login-off-canvas__section.login').show();
					$('.login-off-canvas__section.createaccount').hide();
					$('.login-off-canvas__section.setpassword').hide();
					$('.login-off-canvas__section.loginmessage').hide();
				}else if(data.status == "NOTLOGGEDIN"){
					$('.login-off-canvas__section.checkifexists').hide();
					$('.login-off-canvas__section.login').hide();
					$('.login-off-canvas__section.createaccount').hide();
					$('.login-off-canvas__section.setpassword').show();
					$('.login-off-canvas__section.loginmessage').hide();
				}else{
					$('.login-off-canvas__section.checkifexists').hide();
					$('.login-off-canvas__section.login').hide();
					$('.login-off-canvas__section.createaccount').show();
					$('.login-off-canvas__section.setpassword').hide();
					$('.login-off-canvas__section.loginmessage').hide();
				}

			},
			error : function(data){
				var errors = $.parseJSON(data.responseText);
				//console.log(errors);

				setTimeout(
					function()
					{

						errorsHtml = '<ul class="list-unstyled">';
						$.each( errors , function( key, value ) {
							
							if(key == "message"){
								//console.log(value);
								if(value[0] == "You shall not pass!"){
									value[0] = 'We konden niet verifiëren of je een echte gebruiker bent';
								}
								errorsHtml += '<li style="color:#35393b;">' + value[0] + '</li>'; //showing only the first error.
							}
						});
						errorsHtml += '</ul>';
					
						$('#'+formid+' #form-errors').html( errorsHtml ).show(); //appending to a <div id="form-errors"></div> inside form
					

					}, 1500);

			}

		},"json");
	});
	const stringToBoolean = (string) => string === "true";
    $('#login-form, #reset-form, #register-form, #setpassword-form').on('submit', function(e){
		e.preventDefault();
		if(hasDimensions(this)){
			var $btn = $(this).find('button[type="submit"]');
			var formid = $(this).attr('id');
			$(this).find('#form-errors').empty().hide();
			$(this).find('#form-success').empty().hide();

			var form = jQuery(this);
			var dataString = form.serialize();
			var formAction = form.attr('action');
			var reloadPageVar = $(this).find('.reloadPage').val() || "false";
			var reloadPage = stringToBoolean(reloadPageVar);

			$.ajax({
				type: "POST",
				formid : formid,
				url : formAction,
				data : dataString,
				success : function(data){
				
					console.log(data);

					setTimeout(
						function()
						{
							//var redirectToProfile = false;
							successHtml = '<ul class="list-unstyled">';
							$.each( data.message , function( key, value ) {
								//console.log(value);
								successHtml += '<li style="color:#35393b;">' + value + '</li>'; //showing only the first error.
								
							});
							successHtml += '</ul>';
							if(data.action == "createAccount" || data.action == "setPassword"){
								//redirectToProfile = true;
								window.dataLayer.push({
									'userId' : data.userid,
									'email' : data.useremail
								});
								dataLayer.push({'event': 'CreateAccount'});
							} 
							if(data.action == "login"){
								window.dataLayer.push({
									'userId' : data.userid,
									'email' : data.useremail
								});
								dataLayer.push({'event': 'Login'});
							}
							$('#'+formid+' #form-success').html( successHtml ).show();

							setTimeout(
								function()
								{
									if(!reloadPage){
										window.location.assign('/account');
									}else{
										window.location.reload();
									}

								}, 2000);

						}, 1500);

				},
				error : function(data){
					var errors = JSON.parse(data.responseText);
					console.log(data);

					setTimeout(
						function()
						{

							errorsHtml = '<ul class="list-unstyled">';
							$.each( data.responseJSON , function( key, value ) {
								if(key == "message"){
									//console.log(value);
									if(value == "You shall not pass!"){
										value = 'We konden niet verifiëren of je een echte gebruiker bent';
									}
									errorsHtml += '<li style="color:#35393b;">' + value + '</li>'; //showing only the first error.
								}

							});
							errorsHtml += '</ul>';
						
							$('#'+formid+' #form-errors').html( errorsHtml ).show(); //appending to a <div id="form-errors"></div> inside form
						

						}, 1500);

				}

			},"json");
		}
	});
	
	$('input[name="new-password_confirmation"]').on('focusout', function(e){
		if($('input[name="new-password_confirmation"]').val() != $('input[name="new-password"]').val()){
			$( ".status-message-password" ).html( "De wachtwoorden komen <strong>niet overeen</strong>" );
		}else{
			$( ".status-message-password" ).html( "De wachtwoorden komen overeen" );
		
		}
	});
	$('input.check-email-exists').on('focusout', function(e){
		/*
		
		TO-DO: only execute when it's a real e-mailaddress
		
		*/
		$('.email-exists').hide();
		$('.email-exists-errors').hide();
		$('.email-loginlink-sent').hide();
		//var payload = '{email:"'+$('input.check-email-exists').val()+'"}';
		//console.log(payload);
		$.ajax({
			type: "POST",
			url : "/emailcontroleren",
			data : $('#register-form').serialize(),
			success : function(data){
			
				//console.log(data);
				setTimeout(
					function()
					{
						successHtml = '<ul class="list-unstyled">';
						$.each( data , function( key, value ) {
							//console.log(value);
							//console.log(key+" "+value);
							if(value != "OK"){
								$('.email-exists').show();
							}else{
								$('.email-exists').hide();
							
							}
						});
						successHtml += '</ul>';
						

					}, 1500);
			},
			error : function(data){
				var errors = $.parseJSON(data.responseText);
				//console.log(errors);

				setTimeout(
					function()
					{

						errorsHtml = '<ul class="list-unstyled">';
						$.each( errors , function( key, value ) {
							if(key == "message"){
								//console.log(value);
								if(value == "You shall not pass!"){
									value = 'We konden niet verifiëren of je een echte gebruiker bent';
								}
								errorsHtml += '<li style="color:#35393b;">' + value + '</li>'; //showing only the first error.
							}
						});
						errorsHtml += '</ul>';
					
						$('.email-exists-errors').html(errorsHtml).show();
					

					}, 1500);

			}

		});
	});

});