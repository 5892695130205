/**
 * checkout.js
 */
 
function countDecimals(value){
    if(Math.floor(value) === value) return 0;
    //console.log('fff '+value.toString().split(".")[1].length);
    return value.toString().split(".")[1].length || 0; 
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
	
$(function () {
    $('.checkout__switch__item').on('mouseover', function(){
        var type = $(this).data('type');
        $('.checkout__switchinformation .checkout__notice').hide();
        $('.checkout__switchinformation .checkout__notice.'+type).show();
    });
    $('.checkout__switch__item').on('mouseleave', function(){
        $('.checkout__switchinformation .checkout__notice').hide();
        $('.checkout__switchinformation .checkout__notice.default').show();
    });
    $('.checkout__switch__item').on('click', function(){
        $('.checkout__switch__item').removeClass('selected');   
        var type = $(this).data('type');
        $('.checkout__switch__item[data-type="'+type+'"]').addClass('selected');
        $('.checkout__receipt__notice').hide();
        $('.checkout__receipt__notice.'+type).show();
        if(type == "stap"){
            $('.checkout__receipt__totals__type').text('STAP-reservering');

        }else{
            $('.checkout__receipt__totals__type').text(capitalizeFirstLetter(type));
        }
        $.post('/cart/changetype', { choice: type})
				.done(function( data, element ) {
					//alert( "Data Loaded: add " + data.status );
					if(data.status == "OK"){
						//alert('yaay1');
						location.reload();
						//$('.stap-hide').hide();
						//$('.cart-row__total-active-discount, .cart-row__totals-korting').hide();
					}
				  });
        
    });
    $('input[name="type"]').on('click', function(){
        if($(this).val() == "particulier"){
            $('.checkout__form__row.zakelijk').hide();
            //$('.checkout__form.type').removeClass('selected');
        }else{
            $('.checkout__form__row.zakelijk').show();
            //$('.checkout__form.type').addClass('selected');

        }
    });
    $('input[name="opmerkingen-opgeven"]').on('click', function(){
        if($(this).is(":checked")){
            $('.checkout__form__row.opmerkingen').show(400);
        }else{
            $('.checkout__form__row.opmerkingen').hide(400);

        }
    });
    $('input[name="factuuradres"]').on('click', function(){
        if($(this).is(":checked")){
            $('.checkout__form.factuuradres').show(400);
        }else{
            $('.checkout__form.factuuradres').hide(400);

        }
    });
    $('input[name="gadgetadres"]').on('click', function(){
        if($(this).is(":checked")){
            $('.checkout__form.gadgetadres').show(400);
        }else{
            $('.checkout__form.gadgetadres').hide(400);

        }
    });
    $('.checkout__item input').on('change', function(){
        var category = $(this).parent().parent().data('category');
        var type = $(this).parent().parent().data('category');
        if(category == "verplaatsing"){
            if($(this).is(":checked")){
                $('.checkout__item[data-category="'+category+'"]').removeClass('selected');
                $('.checkout__item[data-category="'+category+'"] input').prop('checked', false);
            }else{
                $('.checkout__item[data-category="'+category+'"]').addClass('selected');
                $('.checkout__item[data-category="'+category+'"] input').prop('checked', true);
            }
            recalculateTotals();
        }else if(category == "premium"){
            if($(this).is(":checked")){
                $('.checkout__item[data-category="'+category+'"]').removeClass('selected');
                $('.checkout__item[data-category="'+category+'"] .checkout__item__selection input').prop('checked', false);
                $('form.remove-premium').trigger('submit');
            }else{
                $('.checkout__item[data-category="'+category+'"]').addClass('selected');
                $('.checkout__item[data-category="'+category+'"] .checkout__item__selection input').prop('checked', true);
                $('form.add-premium').trigger('submit');
            }
        }else if(category == "payment"){
            if($(this).is(":checked")){
                $('.checkout__item[data-category="'+category+'"]').removeClass('selected');
                $(this).parent().parent().addClass('selected');
            }else{
                $(this).prop('checked', true); ;
                $('.checkout__item[data-category="'+category+'"]').removeClass('selected');
                $(this).parent().parent().addClass('selected');
            }
        }
    });
    var isSubmitting = false;
    $('.form.add-premium, form.remove-premium').on('submit', function(event) {
        console.log('test');
        event.preventDefault(); // Prevent the default form submission
        if (isSubmitting) {
            return; // Stop the function if the form is already submitting
        }
        // Set isSubmitting to true to indicate the process has started
        isSubmitting = true;
        var action = $(this).attr('action');
        if(window.innerWidth > 1024){
            var layoutselector = '.desktop_layout_checkout';
        }else{
            var layoutselector = '.mobile_layout_checkout';
        }
        $.ajax({
            type: 'POST',
            url: action,
            data: $(this).serialize(), // Serializes form input for submission
            success: function(response) {
                // Handle success action
                //alert('Form submitted successfully');
                isSubmitting = false;

                $('#shopping-cart-body').removeClass('disabled');
                var receipt = $(response).find('.checkout__receipt').html();
                var body = $(response).find('#shopping-cart-body').html();
                $('.checkout__receipt').html(receipt);
                $(layoutselector+' #shopping-cart-body').html(body);
            },
            error: function() {
                // Handle error action
                console.log('Form submission failed');
                isSubmitting = false;
            }
        });
    });
    $('.checkout__item').on('click', function(){
        $(this).find('input[type="checkbox"], input[type="radio"]').trigger('change');
    });
    $('.checkout__item input[type="checkbox"], input[type="radio"]').on('click', function(e){

        var category = $(this).parent().parent().data('category');
        if(category == "verplaatsing"){
            e.preventDefault();
        } 
        if(category == "premium"){
            e.preventDefault();
        }
        $(this).trigger('change');
    });
    $('.form-checkout__payment-method').hide();

	function recalculateTotals(){
        if(window.innerWidth > 1024){
            var layoutselector = '.desktop_layout_checkout';
            var checkboxselector = '.desktop_layout_checkout #form-checkout .cancellation-select';
        }else{
            var layoutselector = '.mobile_layout_checkout';
            var checkboxselector = '.mobile_layout_checkout #form-checkout .cancellation-select';
        }
        var totaal = parseFloat($('#totaal').val().replace(',', '.'));
        var subtotaal = parseFloat($('#subtotaal').val().replace(',', '.'));
        var btw = parseFloat($('#btw').val().replace(',', '.'));
        //console.log('btw '+btw);
        var idealkorting = parseFloat($('#idealkorting').val().replace(',', '.'));
        var cfbudget = parseFloat($('#cfbudget').val().replace(',', '.'));
        //console.log('idealkorting'+idealkorting);
        var idealkortingtotaal = totaal - idealkorting;
        var idealkortingzonderbtw = idealkorting /121*100;
        var idealkortingbtw = idealkorting - idealkortingzonderbtw;
        var totaalverplaatsing = totaal + idealkorting;
        var nieuwsubtotaal = 0;
        var nieuwbtw = 0;
        var nieuwtotaal = 0;
        var nieuwtotinclidealkorting = 0;
        var nieuwstaptotaal = 0;
        var nieuwtotaalin3 = 0;
        var subtotaalstring = '';
        var btwstring = '';
        var totaalstring = '';
        var totinclidealkortingstring = '';
        var idealkortingstring = '';
        var totaalin3string = '';
        //console.log(btw);
        var betaalmethode = $(layoutselector+' .checkout__item--paymentmethod input:checked').val();
        //console.log(betaalmethode);
        //console.log(idealkorting);
        if($(checkboxselector).is( ":checked" ) ){
        	//De verplaatsingsgarantie is aangevinkt, dus ideal korting bijtellen.
        	if(betaalmethode == "ideal"){
                //Als de betaalmethode ideal is aangevinkt mag het normaal zijn.
                nieuwsubtotaal = subtotaal;
                nieuwbtw = btw;
                nieuwtotaal = totaal;
                nieuwtotinclidealkorting = parseFloat(totaal-idealkorting);
                nieuwtotinclcfbudget = nieuwtotaal - cfbudget;
                if(nieuwtotinclcfbudget < 0) nieuwtotinclcfbudget = 0;
                if(nieuwtotinclidealkorting > 1000){
                    nieuwstaptotaal = parseFloat(totaal+idealkorting) - 1000;
                }else{
                    nieuwstaptotaal = 0;
                }
                nieuwtotaalin3 = parseFloat((totaal)/3);
            }else{
                //nieuwsubtotaal = parseFloat(subtotaal+idealkortingzonderbtw);
                nieuwsubtotaal = subtotaal;
                nieuwbtw = parseFloat(btw+idealkortingbtw);
                nieuwtotaal = parseFloat(totaal+idealkorting);
                nieuwtotinclidealkorting = parseFloat(totaal);
                nieuwtotinclcfbudget = nieuwtotaal - cfbudget;
                if(nieuwtotinclcfbudget < 0) nieuwtotinclcfbudget = 0;
                if(nieuwtotinclidealkorting > 1000){
                    nieuwstaptotaal = parseFloat(totaal+idealkorting) - 1000;
                }else{
                    nieuwstaptotaal = 0;
                }
                nieuwtotaalin3 = parseFloat((totaal+idealkorting)/3);
            }

            $('.cancellationfee').show();
            $('.includingcancellation').show();
            $('.excludingcancellation').hide();
            
        	//nieuwsubtotaal = subtotaal;
        	//nieuwbtw = btw;
        	//nieuwtotaal = totaal;
        	//nieuwidealkorting = parseFloat(totaal-idealkorting); 
        }else{
        	
            //De verplaatsingsgarantie is niet aangevinkt, dus zorgen dat het normaal wordt.
        	if(betaalmethode == "ideal"){
                //Als iDeal gevinkt is, dit laten zienn
                //nieuwsubtotaal = parseFloat(subtotaal-idealkortingzonderbtw);
                nieuwsubtotaal = subtotaal;
                nieuwbtw = parseFloat(btw-idealkortingbtw);
                nieuwtotaal = parseFloat(totaal-idealkorting);
                nieuwtotinclidealkorting = parseFloat(totaal-idealkorting);
                nieuwtotinclcfbudget = nieuwtotinclidealkorting - cfbudget;
                if(nieuwtotinclcfbudget < 0) nieuwtotinclcfbudget = 0;
                if(nieuwtotinclidealkorting > 1000){
                    nieuwstaptotaal = parseFloat(totaal) - 1000;
                }else{
                    nieuwstaptotaal = 0;
                }
                nieuwtotaalin3 = parseFloat((totaal-idealkorting)/3);
            }else{
                nieuwsubtotaal = subtotaal;
                nieuwbtw = btw;
                nieuwtotaal = totaal;
                nieuwtotinclidealkorting = parseFloat(totaal-idealkorting);
                nieuwtotinclcfbudget = nieuwtotaal - cfbudget;
                if(nieuwtotinclcfbudget < 0) nieuwtotinclcfbudget = 0;
                if(nieuwtotinclidealkorting > 1000){
                    nieuwstaptotaal = parseFloat(totaal) - 1000;
                }else{
                    nieuwstaptotaal = 0;
                }
                nieuwtotaalin3 = parseFloat((totaal)/3);
            }
            $('.cancellationfee').hide();
            $('.includingcancellation').hide();
            $('.excludingcancellation').show();
        }
        //console.log(nieuwsubtotaal);
        //console.log(countDecimals(nieuwsubtotaal));
        if(countDecimals(nieuwsubtotaal) > 0){
            //console.log('test');
        	subtotaalstring = (nieuwsubtotaal.toFixed(2)).toString().replace(/\./g, ',');
        }else{
            //console.log('test2');
        	subtotaalstring = nieuwsubtotaal+',-';
        }
        //console.log(nieuwbtw);
        //console.log(countDecimals(nieuwsubtotaal));
        if(countDecimals(nieuwbtw) > 0){
        	nieuwbtw = nieuwbtw.toFixed(2);
        	btwstring = nieuwbtw.toString().replace(/\./g, ',');
        }else{
        	btwstring = nieuwbtw+',-';
        }
        //console.log(nieuwtotaal);
        //console.log(countDecimals(nieuwtotaal));
        if(countDecimals(nieuwtotaal) > 0){
        	totaalstring = (nieuwtotaal.toFixed(2)).toString().replace(/\./g, ',');
        }else{
        	totaalstring = nieuwtotaal+',-';
        }
        if(countDecimals(nieuwtotinclidealkorting) > 0){
        	totinclidealkortingstring = (nieuwtotinclidealkorting.toFixed(2)).toString().replace(/\./g, ',');
        }else{
        	totinclidealkortingstring = nieuwtotinclidealkorting+',-';
        }
        if(countDecimals(nieuwtotinclcfbudget) > 0){
        	totinclcfbudgetstring = (nieuwtotinclcfbudget.toFixed(2)).toString().replace(/\./g, ',');
        }else{
        	totinclcfbudgetstring = nieuwtotinclcfbudget+',-';
        }
        if(countDecimals(nieuwstaptotaal) > 0){
        	totaalstapstring = (nieuwstaptotaal.toFixed(2)).toString().replace(/\./g, ',');
        }else{
        	totaalstapstring = nieuwstaptotaal+',-';
        }
        if(countDecimals(nieuwtotaalin3) > 0){
        	totaalin3string = (nieuwtotaalin3.toFixed(2)).toString().replace(/\./g, ',');
        }else{
        	totaalin3string = nieuwtotaalin3+',-';
        }
        
        $('.checkout__receipt__totals__amounts__line.exclvat .price').text('€' + subtotaalstring);
        $('.checkout__receipt__totals__amounts__line.tax .price').text('€' + btwstring);
        $('.checkout__receipt__totals__amounts__line.normaltotal .price').html('€'+ totaalstring);
        $('.checkout__receipt__totals__amounts__line.cfbudgetnormaltotal .price').html('€'+ totinclcfbudgetstring);
        $('.checkout__receipt__totals__amounts__line.cfbudgetidealtotal .price').html('€'+ totinclcfbudgetstring);
        $('.checkout__notice--totals .total').html('€'+ totaalstring);
        $('.checkout__notice--totalbudget .totalinclstorecredit').html('€'+ totinclcfbudgetstring);
        $('.checkout__receipt__totals__amounts__line.staptotal .price').html('€'+ totaalstapstring);
        //$('.checkout__receipt__totals__amounts__line.paymentmethodprice .price').html('- €'+ idealkorting);
        $('.checkout__receipt__totals__amounts__line.paymentmethodtotal .price').html('€'+ totaalstring);
        $('.checkout__receipt__totals__amounts__line.in3total .price').html('€'+ totaalin3string);
        $('.form-checkout__mollie-total').text('€' + idealkortingstring); 
        
    }
    function determineWhatToShow(betaalmethode){
        if(betaalmethode == "ideal"){
            $('.normaltotal').hide();
            $('.cfbudgetnormaltotal').hide();
            $('.paymentmethodtotal').show();
            $('.cfbudgetidealtotal').show();
            console.log('test'+idealkorting);
            if(idealkorting > 0){
                $('.paymentmethodprice').show();
                $('.idealkortingstring').show();
            }
            $('.in3total').hide();
        }else if(betaalmethode == "in3"){ 
            $('.normaltotal').show();
            $('.cfbudgetnormaltotal').hide();
            $('.paymentmethodprice').hide();
            $('.paymentmethodtotal').hide();
            $('.cfbudgetidealtotal').hide();
            $('.idealkortingstring').hide();
            $('.in3total').show();

        }else{
            $('.normaltotal').show();
            $('.cfbudgetnormaltotal').show();
            $('.paymentmethodprice').hide();
            $('.paymentmethodtotal').hide();
            $('.cfbudgetidealtotal').hide();
            $('.idealkortingstring').hide();
            $('.in3total').hide(); 

        }

    }
    if($('.checkout__item[data-category="payment"]').length){
        var betaalmethode = $('.checkout__item--paymentmethod input').val();
        var idealkorting = parseFloat($('#idealkorting').val().replace(',', '.'));
        recalculateTotals(); 
        determineWhatToShow(betaalmethode);
    }
    $('#form-checkout .premium-select').on('change', function () {
        
    });
	$('#form-checkout .cancellation-select').on('change', function () {
        recalculateTotals(); 
    });
    $('.checkout__item--paymentmethod input').on('change', function(){
        var betaalmethode = $(this).val();
        var idealkorting = parseFloat($('#idealkorting').val().replace(',', '.'));
        recalculateTotals(); 
        determineWhatToShow(betaalmethode);
        

    });
    $('.payment-method-select').on('change', function () {
        $('.form-checkout__payment-method').hide();
        $('#method-' + $(this).val() + '-details').show();
    });

    if ($('#form-checkout .payment-method-select:checked').length) {
        $('#method-' + $('#form-checkout .payment-method-select:checked').val() + '-details').show();
    } else {
        $('.form-checkout__payment-method').hide();
    }

    $('.form-checkout').on("forminvalid.zf.abide", function () {
        if(window.innerWidth > 1024){
            var myElement = document.querySelector('.desktop_layout_checkout .is-invalid-input');
            var topPos = myElement.offsetTop;
            var scrollPos = topPos - 30;
            $('.desktop_layout_checkout__main__top').animate({ scrollTop: scrollPos }, 900);
        }else{
            var myElement = document.querySelector('.mobile_layout_checkout .is-invalid-input');
            var topPos = myElement.offsetTop;
            var scrollPos = topPos - 90;
            //alert(topPos);
            //$(window).animate({ scrollTop: scrollPos }, 900);
            $('html').animate({ scrollTop: scrollPos }, 900);
            //myElement.scrollIntoView();
            //document.scrollTop = scrollPos;
        }
        
    });


    $('#form-checkout').on("valid.zf.abide", function (ev, elem) {
        // Todo tussendoor submitten enzo
    });

    $('#fieldset-factuuradres').on("on.zf.toggler", function () {
        $('#factuuradres').find('input').prop('checked', true);
    });

    $('#fieldset-factuuradres').on("off.zf.toggler", function () {
        $('#factuuradres').find('input').prop('checked', false);
    });
	$('.deelnemer-toggel').on('click', function(){
        var toggle = $(this).data('toggle');
        //console.log(toggle);
        $('.'+toggle).css('max-height','unset');
    });
	$('input[name="anderedeelnemer"]').on('click', function(){
		if($(this). is(":checked")){
			$(".anderedeelnemer").show();
			$(".eendeelnemer").hide();
		}
		else if($(this). is(":not(:checked)")){
			$(".anderedeelnemer").hide();
			$(".eendeelnemer").show();
		}
    });
    if ($('#andere-deelnemers-opgeven').length > 0) {
        var doSwitch = function (newSize) {
            if (newSize == 'small') {
                $('#geen-andere-deelnemers-opgeven').height('auto');
            }
            else {
                setTimeout(function () {
                    $('#geen-andere-deelnemers-opgeven').height($('#andere-deelnemers-opgeven').height());
                }, 0);
            }
        }

        doSwitch(Foundation.MediaQuery.current);

        $(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
            doSwitch(newSize);
        });
    }
});