$(function() {
    $('body').on('change', '.checkout-deelnemer-select', function() {
       var rowId = $(this).data('row');
       var deelnemer = $(this).data('deelnemer');
       //console.log('test '+rowId+' '+deelnemer+' '+$(this).val());

        $('input[name="' + rowId +'-'+deelnemer).val($(this).val());
        $('input[name="boekingDeelnemmer' + deelnemer).val($(this).val());

        var aantalRows = $('#'+ rowId +'-'+deelnemer).children('*[name^="boekingDeelnemmer"]').length;
        var json = {'deelnemerId': $(this).val(),
                    'deelnemer': deelnemer,
                    'aantalRows': aantalRows
        };

        $.ajax({
            type: "POST",
            url : "deelnemers/savedeelnemer",
            data : json,
            success : function(data){
                console.log('goed');
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(xhr.status);
            }
        });    

    });
});