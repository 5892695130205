$(function () {
  $("input[name='color']").on("click", function (e) {
    $("input[name='color']").parent().find('img').css('border','none');
    $(this).parent().find('img').css('border','2px solid #FF9900');
    $(this).parent().find('img').css('border-radius','100%');

  });


  //UPLOADING profile picture
  // Get the element that will trigger the file selection
  var triggerElement = document.getElementById('selfimage'); // Replace 'triggerElementId' with your actual element's ID

  // Get the file input element
  var fileInput = document.getElementById('fileToUpload');
  if(triggerElement){
    // Add a click event listener to the trigger element
    triggerElement.addEventListener('click', function() {
        // Trigger the file input click event
        fileInput.click();
        $("input[name='color']").prop('checked', false);
        $("input[name='color']").parent().find('img').css('border','none');
    });
  }
  if(fileInput){
    // Add a change event listener to the file input
    fileInput.addEventListener('change', function(event) {
        // Assuming you have a form with id 'uploadForm'
        var form = document.getElementById('form-profiel');
        var formData = new FormData(form);
        var xhr = new XMLHttpRequest();
        // Select the #selfimage element
        var selfImageElement = document.getElementById('selfimage');

        // Get the value of the data-userid attribute
        var userId = selfImageElement.getAttribute('data-userid');

        // Get the action attribute from the form
        var actionUrl = form.action;
        
        // Get the selected files
        var files = event.target.files;
        if (files.length > 0) {
            var file = files[0]; 
            // Get the name of the file
            var fileName = file.name;

            // Split the file name by dot (.) and get the last part
            var fileExtension = fileName.split('.').pop();
        }
        xhr.open('POST', actionUrl, true);

        xhr.onload = function () {
            if (xhr.status === 200) {
                // File(s) uploaded.
                //alert('Upload complete!'+userId);

                // Create a new div element
                  var newDiv = document.createElement('div');
                  var newDivhomepage = document.createElement('div');
                  // Set the style of the new div
                  newDiv.style.backgroundImage = "url('/images/profile-images/"+userId+"_profielfoto."+fileExtension+ '?' + new Date().getTime();"')";
                  newDiv.style.backgroundSize = "contain";
                  newDiv.style.width = "48px";
                  newDiv.style.height = "48px";
                  newDiv.style.borderRadius = "100%";
                  newDiv.classList.add("changedimg");
                  if($('.profile-avatar-field').length){
                    newDivhomepage.classList.add("hoverprofileimg");
                  }
                  // Clear current child elements from #selfimage
                  while (selfImageElement.firstChild) {
                      selfImageElement.removeChild(selfImageElement.firstChild);
                  }
  
                  // Append the new div to #selfimage
                  selfImageElement.appendChild(newDiv);
                  if($('.profile-avatar-field').length){
                    newDiv.appendChild(newDivhomepage);
                  }
                
            } else {
                alert('Upload error. Try again.');
            }
        };

        xhr.send(formData);
    
      });
    }


  //UPLOADING lesmateriaal
  var fileInputLes = $(".LesmateriaalUpload");
  if(fileInputLes){
    for (var i = 0; i < fileInputLes.length; i++) {
      fileInputLes[i].addEventListener('change', Lesuploaden, false);
    }
  }
});

$("#form-addinginterests").on('click', function(){
  var $boxes = $("#form-addinginterests").children();
  $boxes.each(function(){
    $ischecked = $(this).children().is(":checked");
    if($ischecked == true){
      $(this).removeClass('interestnotselected');
      $(this).removeClass('interestselected');
      $(this).addClass('interestselected');
    }else{
      $(this).removeClass('interestnotselected');
      $(this).removeClass('interestselected');
      $(this).addClass('interestnotselected');
    }
  });
});

//clone upload field and change numbers for form
$(".fileToUploadLesmateriaal_plus, .upload_lesmateriaal__box__icons__span").on('click', function(){
  var $div = $('.uploadbox:last');
  var num = parseInt( $div.prop("id").match(/\d+/g), 10 ) +1;
  var $klon = $div.clone(true);
  $klon.prop('id', 'uploadbox'+num );
  //$klon.children().children('.upload_lesmateriaal__box__label').text('naam *');
  $klon.children().children('.documentName').attr('name','documentName'+num);
  $klon.children().children('.documentName').attr('id','documentName'+num);

  $klon.children().children('.progressWrapper').attr('id','progressWrapper'+num);
  $klon.children().children('.progressWrapper').children('.progressBar').attr('id','progressBar'+num);
  $klon.children().children('#progressWrapper'+num).hide();

  $klon.children().children('.upload_lesmateriaal__box__upload').children().attr('name','fileToUploadLesmateriaal'+num);
  $klon.children().children('.upload_lesmateriaal__box__upload').children().attr('id','fileToUploadLesmateriaal'+num);
  $klon.children().children('.documentName').val('')
  $klon.children().children('.upload_lesmateriaal__box__upload').children().val('');
  $klon.children().children('.upload_lesmateriaal__box__upload').children()[0].addEventListener('change', Lesuploaden, false);
  $klon.children().children('.upload_lesmateriaal__box__icons').children('.fileToUploadLesmateriaal_min').show();
  $klon.children().children('.upload_lesmateriaal__box__icons').children('.fileToUploadLesmateriaal_min').attr('id','uploadIconMin'+num);

  $div.after($klon);

  $('.fileToUploadLesmateriaal_plus').hide();
  $('.upload_lesmateriaal__box__icons__span').hide();
  $('.fileToUploadLesmateriaal_plus:last').show();
});

//remove a cloned upload field
$(".fileToUploadLesmateriaal_min").on('click', function(){

  //redirect to delete uploaded file
  const num = parseInt( $(this).prop("id").match(/\d+/g), 10 );
  var form = document.getElementById('form-evaluatie');
  var formData = new FormData(form);
  formData.append('num', num);

  var xhr = new XMLHttpRequest();
  var actionUrl = '/lesmateriaal/remove';

  xhr.open('POST', actionUrl, true);

  xhr.onload = function () {
    if (xhr.status === 200) {
    }else{
      $('#lesmateriaalAlertDanger').show()
      $('#lesmateriaalAlertDanger').html('document niet verwijderd, er is iets misgegaan')
    }
  };

  xhr.send(formData);

  var $div = $(this).parent().parent().parent();
  $div.remove();
  $('.fileToUploadLesmateriaal_plus:last').show();
  if($('.uploadbox').length < 2){
    $('.upload_lesmateriaal__box__icons__span').show();
  }
});


var Lesuploaden = function() {
  $('#progressWrapper'+number).hide();
  // Assuming you have a form with id 'form-upload_lesmateriaal'
  var form = document.getElementById('form-evaluatie');
  const num = parseInt( $(this).prop("name").match(/\d+/g), 10 );
  var number = parseInt( $(this).prop("name").match(/\d+/g), 10 );
  var formData = new FormData(form);
  formData.append('num', num);
  var xhr = new XMLHttpRequest();

  // Get the action attribute from the form
  var actionUrl = form.action;

  // Get the selected files
  var files = event.target.files;
  if (files.length > 0) {
      var file = files[0]; 
      // Get the name of the file
      var fileName = file.name;

      // Split the file name by dot (.) and get the last part
      var fileExtension = fileName.split('.').pop();
  }
  xhr.open('POST', actionUrl, true);

  // Display the progress bar
  $('#progressWrapper'+number).show();
  // Track upload progress
  xhr.upload.addEventListener('progress', function (e) {
      if (e.lengthComputable) {
          const percentComplete = (e.loaded / e.total) * 100;
          $('#progressBar'+number).width(percentComplete + '%');
          $('#progressBar'+number).text(Math.round(percentComplete) + '%');
      }
  });

  xhr.onload = function () {
    if (xhr.status === 200) {
      if(xhr.responseText){
        $('#lesmateriaalAlertDanger').show()
        $('#lesmateriaalAlertDanger').html(xhr.responseText)
      }else{
        $('#lesmateriaalAlertDanger').hide()
      }
    }else{
    }
};

xhr.send(formData);
};

