$(function () {
	// Check if there are any .tab-item elements on the page
const tabItems = document.querySelectorAll('.tab-item');

if (tabItems.length > 0) {
    // Function to handle tab item click
    tabItems.forEach(function(tabItem) {
        tabItem.addEventListener('click', function() {

            if (!tabItem.closest('.go-to')) {
                // Remove 'selected' class from all tab items
                document.querySelectorAll('.tab-item').forEach(function(item) {
                    item.classList.remove('selected');
                });
                
                // Add 'selected' class to the clicked tab item
                tabItem.classList.add('selected');
            }
            
            // Get the value from the data-value attribute
            const value = tabItem.getAttribute('data-value');
            
            // If the value is "all", show all sections
            if (value === 'all') {
                if (!tabItem.closest('.go-to')) {
                    document.querySelectorAll('[class^="section-"]').forEach(function(section) {
                        section.style.display = 'block';
                    });
                }
            } else {
                if (!tabItem.closest('.go-to')) {
                    // Hide all sections
                    document.querySelectorAll('[class^="section-"]').forEach(function(section) {
                        section.style.display = 'none';
                    });
                }
                
                // Show the specific section matching the data-value
                const selectedSection = document.querySelector('.section-' + value);
                if (selectedSection) {
                    selectedSection.style.display = 'block';
                    // Check if the parent container has the class .go-to
                    if (tabItem.closest('.go-to')) {
                        // Scroll to the selected section
                        selectedSection.scrollIntoView({
                            behavior: 'smooth', // Adds smooth scrolling
                            block: 'start'      // Scrolls to the start of the section
                        });
                    }
                }
            }
        });
    });
}

// Select all .main-row elements
const mainRows = document.querySelectorAll('.main-row');

// Check if there are any .main-row elements on the page
if (mainRows.length > 0) {
    // Function to handle main-row click
    mainRows.forEach(function(mainRow) {
        mainRow.addEventListener('click', function() {
            // Toggle the selected class on the clicked main-row
            mainRow.classList.toggle('selected');
            
            // Toggle the open class on the .tgl-item inside the clicked main-row
            const toggleItem = mainRow.querySelector('.tgl-item');
            if (toggleItem) {
                toggleItem.classList.toggle('open');
            }

            // Find the .details-row right next to the clicked main-row
            const nextDetailsRow = mainRow.nextElementSibling;
            if (nextDetailsRow && nextDetailsRow.classList.contains('details-row')) {
                // Check if the clicked main-row has a parent with the faqs class
                const hasFaqParent = mainRow.closest('.faqs') !== null;

                // Toggle the visibility of the .details-row
                if (nextDetailsRow.style.display === 'none' || !nextDetailsRow.style.display) {
                    nextDetailsRow.style.display = hasFaqParent ? 'block' : 'table-row';
                } else {
                    nextDetailsRow.style.display = 'none';
                }
            }
        });
    });
}

const dropdownButton = document.getElementById("dropdownButton");
const dropdownMenu = document.getElementById("dropdownMenu");
if (dropdownButton && dropdownMenu) {
    dropdownButton.addEventListener("click", () => {
        dropdownMenu.classList.toggle("show");
        console.log('clicked');
    });

    // Close the dropdown if clicked outside
    window.addEventListener("click", function (e) {
        if (!dropdownButton.contains(e.target) && !dropdownMenu.contains(e.target)) {
            dropdownMenu.classList.remove("show");
        }
    });
}

    const hoverImages = document.querySelectorAll('.hover-image');

    if (hoverImages.length > 0) {
        hoverImages.forEach(function(container) {
            const defaultImg = container.querySelector('.default');
            const hoverImg = container.querySelector('.hover');
            if (hoverImg) { // Check if the hover image exists
                const parent = container.closest('.bxd-content') || container;

                parent.addEventListener('mouseenter', function() {
                    defaultImg.style.display = 'none';
                    hoverImg.style.display = 'block';
                });

                parent.addEventListener('mouseleave', function() {
                    defaultImg.style.display = 'block';
                    hoverImg.style.display = 'none';
                });
            }
        });
    }

});