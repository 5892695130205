$(function () {
    $('.clickableCross').hide();

    $('#SearchBar input').on('keyup', function () {
        var query = $(this).val().trim().toLowerCase();
        $(".grid-view .grid-item").show().filter(function () {
			return $(this).find('p.training-grid-block__title').text().toLowerCase().indexOf(query) < 0;
		}).hide();
        $(".list-view .grid-item").show().filter(function () {
			return $(this).find('h3').text().toLowerCase().indexOf(query) < 0;
		}).hide();
        $('.clickableCross').show();

        if ($(window).width() < 640) {
            $('.Searchicon').hide();
        }
    });

    $('.clickableCross').on('click', function () {
        $(".grid-view .grid-item").show();
        $(".list-view .grid-item").show();
        if ($(window).width() < 640) {
            $('.Searchicon').show();
        }
        $('#SearchBar input').val('');
        $('.clickableCross').hide();
    });
});