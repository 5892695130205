function pauseAllVideos() 
    { 
        $('iframe').contents().find('video').each(function () 
        {
            this.pause();
        });
        $('video').each(function () 
        {
            this.pause();
        });
    }

(function ($, window, undefined) {
  'use strict';

  $('[data-reveal]').on('closed.zf.reveal', function () {
    //var modal = $(this);
    //alert('closed');
    
	//var videoElement = $(this).closest('.reveal').find('iframe');
	//var srcReveal = videoElement.data('src');
	//videoElement.attr('src', '');
	pauseAllVideos();

	//videoElement.attr('src', srcReveal);
	//videoElement.contentDocument.location.reload(true);
	//console.log(srcReveal);
  	//var src = $('#video1 iframe').data('src');
  	//$('#video1 iframe').attr('src', '');
  	//$('#video1 iframe').attr('src', src);
	//console.log(src);
  	//var src2 = $('#video2 iframe').attr('src');
  	//$('#video2 iframe').attr('src', src2);
  	//var src3 = $('#videohead iframe').attr('src');
  	//$('#videohead iframe').attr('src', src3);
  	//$videoEl.stopVideo();
  	//$videoEl.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')*/
	//  pauseAllVideos();
  });

  $('[data-reveal]').on('open.zf.reveal', function () {
    //var modal = $(this);
    //alert('closed');
	//alert('yep');
    
	
	//var videoElement = $(this).closest('.reveal').find('iframe');
	//var srcReveal = videoElement.data('src');
	//videoElement.attr('src', srcReveal);
	//pauseAllVideos();

	//videoElement.attr('src', srcReveal);
	//videoElement.contentDocument.location.reload(true);
	//console.log(srcReveal);
  	//var src = $('#video1 iframe').data('src');
  	//$('#video1 iframe').attr('src', '');
  	//$('#video1 iframe').attr('src', src);
	//console.log(src);
  	//var src2 = $('#video2 iframe').attr('src');
  	//$('#video2 iframe').attr('src', src2);
  	//var src3 = $('#videohead iframe').attr('src');
  	//$('#videohead iframe').attr('src', src3);
  	//$videoEl.stopVideo();
  	//$videoEl.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')*/
	//  pauseAllVideos();
  });

  /*$(document).foundation();*/


})(jQuery, this);

